import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"
import SplitSection from "../components/SplitSection"

import PageLinkFade from "../components/PageTransitionFade"
import TextWithLogo from "../components/TextWithLogo"
import PageTransition from "../components/pageTranisition"
import gsap from "gsap"

const PageTranNext = ({ mobile, changePageClick, nextPageSlug, nextPageTitle }) => {
  if (mobile === false) {
    return (
      <>
        <button onClick={changePageClick} className='m-l-2 m-b-20 h-117 w-117 relative flex flex-j-center flex-center-h flex-center-v'>
          <svg width='117' height='117' className='progress-circle absolute' id='progress-circle'>
            <circle stroke='black' strokeWidth='1' fill='transparent' r='56.5' cx='58.5' cy='58.5'></circle>
          </svg>
          <div className='button z-6 c-p m-b--3' id='next-page'>
            Next
          </div>
        </button>
        <button onClick={changePageClick}>
          <h1 className='c-2A2A2A'>{nextPageTitle}</h1>
        </button>
      </>
    )
  } else {
    return (
      <>
        <PageLinkFade className='button z-6 c-p next m-b--3' id='next-page' to={nextPageSlug}>
          <div className=' m-b-20 h-117 w-117 relative flex flex-j-center flex-center-h flex-center-v m-l-2'>
            <svg width='117' height='117' className='progress-circle absolute' id='progress-circle'>
              <circle stroke='black' strokeWidth='1' fill='transparent' r='56.5' cx='58.5' cy='58.5'></circle>
            </svg>
            <span className='m-b--3 '>Next</span>
          </div>
        </PageLinkFade>
        <PageLinkFade to={nextPageSlug} id='page-tran-fade'>
          <h1 className='c-2A2A2A'>{nextPageTitle}</h1>
        </PageLinkFade>
      </>
    )
  }
}

const Links = ({ mobile, linksArray, currentPosition }) => {
  let links = linksArray
  if (linksArray[0].id !== "thetrip") links = linksArray.reverse()
  if (mobile === true) {
    const mobileLinksArray = linksArray.slice(currentPosition, currentPosition + 3)
    if (mobileLinksArray.length === 2) {
      mobileLinksArray.push(linksArray[0])
    }
    if (mobileLinksArray.length === 1) {
      mobileLinksArray.push(linksArray[0])
      mobileLinksArray.push(linksArray[1])
    }
    if (mobileLinksArray.length === 0) {
      mobileLinksArray.push(linksArray[0])
      mobileLinksArray.push(linksArray[1])
      mobileLinksArray.push(linksArray[2])
    }
    return (
      <div className='flex flex-center-h p-b-40'>
        <div className=' m-w-690 m-a flex-grow-1 flex-s-b flex p-r-30 p-l-30'>
          {mobileLinksArray.map(link => (
            <div key={link.id} className='flex flex-c flex-center-h flex-center-v' id={link.id}>
              <PageLinkFade to={link.slug}>
                <span className='c-2A2A2A w-118 flex flex-center-h  nav alt-11 text-center'>{link.title}</span>
              </PageLinkFade>
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className='flex flex-center-h p-b-20'>
        <div className=' m-w-690 m-a flex-grow-1 flex-s-b flex '>
          {links.map(link => (
            <div key={link.id} className='flex flex-c' id={link.id}>
              <PageLinkFade to={link.slug}>
                <span className='c-2A2A2A   nav'>{link.title}</span>
              </PageLinkFade>
              <div className={`h-1 back-black  w-100-p ${link.position === currentPosition ? "" : "o-0"}`}></div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default class theTrip extends React.Component {
  constructor(props) {
    super(props)

    //basic bindings -- boiler plate code
    this.pageTranScroll = this.pageTranScroll.bind(this)
    this.changeLocation = this.changeLocation.bind(this)
    this.pageTranScroll0 = this.pageTranScroll0.bind(this)
    this.changePageClick = this.changePageClick.bind(this)
    this.changeTransitionSizeTo0 = this.changeTransitionSizeTo0.bind(this)
    this.changeTransitionSizeTo10 = this.changeTransitionSizeTo10.bind(this)
    this.state = {
      timer: null,
      mobile: false,
    }
  }

  componentDidMount() {
    window.scrollY = 0
    const nav = document.querySelector("#nav-bar")
    if (window.innerWidth < 768) {
      this.setState({ mobile: true })
    }
    const body = document.querySelector("body")
    body.style.overflow = "unset"

    // drop in the nav bar if the pages has not been trasnitioned from another discover page -- bad way to do

    if (!nav.classList.contains("page-tran")) {
      let tl = gsap.timeline({ delay: 0.5 })
      tl.to("#title", { visibility: "visible" })
      tl.to("#title", { opacity: 1, duration: 1, ease: "ease" })
      tl.to("#nav-bar", {
        y: "0%",
        duration: 0.75,
        ease: "power1.out",
        delay: 0.5,
      })
    }

    // chnages the progress bar to 0 initally

    const progress = document.querySelector("#progress-circle")
    progress.style.strokeDashoffset = `355px`

    // underlines the link in the bottom section depenat on the current page

    /*========================================================================================================================================================================================================
     *
     * 1.1
     *
     * Recives event trasmitted from header when menu opened
     * and changes progress bar to 0 and chnages the size
     * of the bottom section to 0 also
     *
     */

    document.addEventListener("openMenu", () => {
      this.pageTranScroll0()
      this.changeTransitionSizeTo0()
    })
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer)
    //cleans up 1.1 function to perevnt memory loss
    this.setState({ timer: null })
    document.removeEventListener("openMenu", () => {
      this.pageTranScroll0()
      this.changeTransitionSizeTo0()
    })
  }

  /*========================================================================================================================================================================================================
   *
   * Change apperance of progress bar based upon scroll
   *
   */

  //change progress bar to full

  pageTranScroll() {
    if (window.innerWidth > 768) {
      this.setState({
        timer: setTimeout(() => {
          this.changeLocation()
        }, 2250),
      })

      let tl = gsap.timeline()

      tl.add("one")

      tl.to(
        "#progress-circle",
        {
          strokeDasharray: `355px 355px`,
          strokeDashoffset: 0,
          duration: 2.25,
          ease: "linear",
        },
        "one"
      )
    }
  }

  //change progress bar to empty

  pageTranScroll0() {
    if (window.innerWidth > 768) {
      clearTimeout(this.state.timer)
      this.setState({ timer: null })

      let tl = gsap.timeline()

      tl.to("#progress-circle", {
        strokeDasharray: `355px 355px`,
        strokeDashoffset: 355,
        duration: 2.25,
        ease: "linear",
      })
    }
  }

  /*========================================================================================================================================================================================================*/

  /*========================================================================================================================================================================================================
   *
   *  Change Page location
   *
   */

  //change location by progress bar / scroll to bottom

  changeLocation() {
    clearTimeout(this.state.timer)

    const link = document.querySelector("#page-transition")
    const pageLink = document.querySelector(".next")

    if (link && this.state.timer !== null && window.innerWidth > 768) {
      link.click()
    } else if (this.state.timer !== null && window.innerWidth < 768) {
      pageLink.click()
    }
  }

  //change location by click on next button

  changePageClick() {
    if (window.innerWidth > 768) {
      const link = document.querySelector("#page-transition")
      link.click()
    }
  }

  /*========================================================================================================================================================================================================*/

  /*========================================================================================================================================================================================================
   *
   * change height of bottom section
   *
   */

  //change size of bottom section

  changeTransitionSizeTo10() {
    if (window.innerWidth > 768) {
      let tl = gsap.timeline()
      tl.to("#page-tran-w", { height: `5vh`, duration: 1, ease: "power1.out" })
    }
  }

  changeTransitionSizeTo0() {
    if (window.innerWidth > 768) {
      let tl = gsap.timeline()
      tl.to("#page-tran-w", { height: `0vh`, duration: 1, ease: "power1.out" })
    }
  }

  /*========================================================================================================================================================================================================*/

  /*========================================================================================================================================================================================================
   *
   *
   *
   */

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            current: datoCmsDiscover(title: { eq: "The Taste" }) {
              title
              heroVideoLink
              splitTitle
              splitDescription
              buttonText
              position
              fallbackImage {
                gatsbyImageData
              }
              splitImage {
                gatsbyImageData
              }
              useLinkInstead
              link
            }
            next: datoCmsDiscover(position: { eq: 4 }) {
              title
              fallbackImage {
                gatsbyImageData
              }
            }
            links: allDatoCmsDiscover {
              edges {
                node {
                  title
                  position
                }
              }
            }
          }
        `}
        render={({ current, next, links }) => {
          const { title, heroVideoLink, fallbackImage, splitTitle, buttonText, splitDescription, splitImage, position } = current
          const nextPageSlug = `/${next.title.toLowerCase().replace(/\s/g, "")}`
          const nextPageImage = next.fallbackImage
          const linksArray = links.edges.map(({ node }) => {
            return { slug: `/${node.title.toLowerCase().replace(/\s/g, "")}`, title: node.title, position: node.position, id: node.title.toLowerCase().replace(/\s/g, "") }
          })
          return (
            <Layout changeTransitionSizeTo0={this.changeTransitionSizeTo0} changeTransitionSizeTo10={this.changeTransitionSizeTo10} pageTranScroll={this.pageTranScroll} pageTranScroll0={this.pageTranScroll0}>
              <Header className='back-tran  m-a-1-n-b' isLink={current.useLinkInstead} link={current.link}/>
              <div data-scroll-container id='scroll-container' className='data-scroll-container fixed'>
                <Seo title={title} />
                <section data-scroll data-scroll-call='invertNavTran' data-scroll-repeat='true' data-scroll-offset='90%'>
                  <div className='h-100-vh w-100-vw relative flex'>
                    <GatsbyImage alt='' image={fallbackImage.gatsbyImageData} className='bg-image z-2' objectFit='cover' placeholder='dominantColor' loading='eager' />
                    <div className='bg-image z-3'>
                      <video className='bg-image Z-3 ' muted='muted' autoPlay loop={true} playsInline={true}>
                        <source src={heroVideoLink} type='video/mp4' />
                        <track kind='captions'></track>
                      </video>
                    </div>

                    <div className='w-100-p h-100-p z-3 absolute flex flex-center-h flex-center-v flex-c'>
                      <div className='m-b-40'>
                        <h1 className='c-white o-p-0' id='title'>
                          {title}
                        </h1>
                      </div>
                    </div>
                  </div>
                </section>
                <section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
                  <SplitSection
                    className='h-100-vh'
                    right={
                      <div data-scroll data-scroll-call='scale' className='h-100-p' data-scroll-offset='-100%'>
                        <GatsbyImage alt='' image={splitImage.gatsbyImageData} className='w-100-p h-100-p ' layout='fullWidth' placeholder='dominantColor' loading='lazy' />
                      </div>
                    }
                  >
                    <TextWithLogo isLink={current.useLinkInstead} to={current.link ?? '/contact' } buttonText={buttonText} title={splitTitle} text={splitDescription} />
                  </SplitSection>
                </section>
                <section data-scroll data-scroll-call='invertNav' data-scroll-repeat='true' data-scroll-offset='90%'>
                  <div className='p-t-190 back-white b-t-1-b flex flex flex-center-h flex-c p-b-25vh'>
                    <div className='flex flex-center-h flex-center-v flex-c p-b-210 z-2'>
                      <PageTranNext mobile={this.state.mobile} changePageClick={this.changePageClick} nextPageSlug={nextPageSlug} nextPageTitle={next.title} />
                    </div>
                    <Links mobile={this.state.mobile} linksArray={linksArray} currentPosition={position} />
                  </div>
                </section>
                <section data-scroll data-scroll-call='triggerPageTran' data-scroll-repeat='true' data-scroll-offset='-1%'></section>
              </div>
              <div className='h-0  m-h-5 over-h w-100-p b-0 over-h z-3 fixed' id='page-tran-w'>
                <div data-scroll data-scroll-call='triggerPageTran' data-scroll-repeat='true' className='h-100-vh d-n-b   over-h w-100-p no-events ' id='page-tran'>
                  <PageTransition to={nextPageSlug} className='no-events '>
                    <GatsbyImage alt='' image={nextPageImage.gatsbyImageData} className='w-100-p bg-image gradient h-100-vh' objectFit='cover' placeholder='dominantColor' loading='eager' />
                  </PageTransition>
                </div>
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}
